<template>
  <div id="SolutionOne">
    <!-- SolutionOne头部内容 -->
    <div class="SolutionOneHeader">
      <!-- 头部组件 -->
      <Header />
    </div>
    <!-- SolutionOne中间内容 -->
    <div class="SolutionOneCenter">
      <!-- 产品简介 -->
      <div class="producrPro">
        <div class="protectProTit">产品简介</div>
        <div class="protectProCon">
          <img src="~assets/img/SolutionOne/产品简介@2x.png" alt="" />
          <ul class="producrProRight">
            <li v-for="(item, index) in producrProRight" :key="index">
              <div class="producrProRiTit">{{ item.Title }}</div>
              <div class="producrProRiCon">
                {{ item.Text }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 企业数字工厂业务场景 -->

      <div class="MathCompany">
        <div class="MathCompanyTit">
          企业数字工厂业务场景
        </div>
        <img
          class="MathCompanyImg"
          src="~assets/img/SolutionOne/业务场景@2x.png"
          alt=""
        />
        <ul class="MathCompanyDetail">
          <li v-for="(item,index) in MathCompanyDetail" :key="index" >
            <div class="headTop">
              <div class="headTopTit">
                {{item.Titles}}
              </div>
              <img
                class="MathComIcons"
                :src="item.icons"
                alt=""
              />
            </div>
            <div class="MathComContent">
              {{item.text}}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
        <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/SolutionOne/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "SolutionOne",
  data() {
    return {
      producrProRight: [
        {
          Title: "产品特点",
          Text:
            "紧固件行业数字化管控系统主要面向紧固件生产企业，通过生产设备传感改造和网络建设，构建覆盖主要生产设备的物联网监控系统，实现设备状态、作业过程、生产量的实时采集和统计，打造ERP + MES + WMS 铁三角组合，紧贴紧固件企业生产管理的特点，实现从销售订单到发货的全流程数字化管控。"
        },
        {
          Title: "产品优势",
          Text:
            "该系统软硬件由象来科技（宁波）有限公司结合紧固件行业经验自主研发，2020年入选镇海区优秀工业APP，先后应用于温州、宁波、广东多家紧固件生产企业，大幅提升设备利用率，提升生产效率，缩短生产周期，减少原材料浪费。"
        },
        {
          Title: "产品案例",
          Text:
            "在宁波打造的群力、金环数字化车间项目被评为镇海区2021年“数字化车间”示范项目。"
        }
      ],
      MathCompanyDetail: [
          { Titles: '采购管理', icons: require('assets/img/SolutionOne/icon-采购管理@2x.png'), text: '各部门进行生产辅料及办公用品采购申请，同时MRP运算完自动加入的采购需求池。形成采购交期达成率报表，便于供应商的评级选择。' },
          { Titles: '产品管理', icons: require('assets/img/SolutionOne/icon-产品管理@2x.png'), text: '建立了包括4000余种紧固件标准型号的数据库，提供标准尺寸对照表参照，实现自动编码及bom一站式建立。' },
          { Titles: '销售管理', icons: require('assets/img/SolutionOne/icon-销售管理@2x.png'), text: '通过报价单严格管控销售价格权限，保证价格保密。发货退货全链条追溯保证账务清晰。通过调价功能实现价格变动有迹可循。实现一键式销售对账。' },
          { Titles: '外协管理', icons: require('assets/img/SolutionOne/icon-外协管理@2x.png'), text: '按供应商及对应外协物料材质进行报价，外协制令由MRP运算自动生成。' },
          { Titles: '生产制造执行', icons: require('assets/img/SolutionOne/icon-生产制造@2x.png'), text: '实时采集设备的运行状态和生产数量等数据，辅助计划人员进行排产，员工通过工位电脑查看工艺文件、任务进度和报工。' },
          { Titles: '品质管理', icons: require('assets/img/SolutionOne/icon-品质管理@2x.png'), text: '员工扫入工单，选择检验类型后，系统根据该工单的物料信息带出相关检验标准，根据检验数量，生成相关检验条目。' },
          { Titles: '仓储管理', icons: require('assets/img/SolutionOne/icon-仓储管理@2x.png'), text: '仓储管理和生产执行系统进行集成，原料、半成品、线边库通过仓储管理进行管控，所有产品材料领用通过工单进行。通过仓储管理应用管理原材料批号，并对库区进行划分。' },
          { Titles: '数据中台', icons: require('assets/img/SolutionOne/icon-数据众泰@2x.png'), text: '数字化工厂驾驶舱、设备监控、工单监控、在制品管理、OEE、准时交货率统计、SPC、缺陷柏拉图等。' },
      ]
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scoped>
#SolutionOne {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // SolutionOne头部样式
  .SolutionOneHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/SolutionOne/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }
  //   SolutionOne中间内容
  .SolutionOneCenter {
    width: 1220px;
    margin: 80px auto 102px;
    // 产品简介
    .producrPro {
      .protectProTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
      }
      .protectProCon {
        height: 390px;
        margin-top: 30px;
        display: flex;
        // overflow: hidden;
        img {
          width: 572px;
          height: 390px;
          border-radius: 10px;
        }
        .producrProRight {
          height: 390px;
          padding: 0;
          margin: 0px 0px 0px 48px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          li {
            .producrProRiTit {
              width: 92px;
              height: 36px;
              background: #e7f3fe;
              border-radius: 6px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 36px;
              color: #1e90ff;
              text-align: center;
            }
            .producrProRiCon {
              width: 600px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.85);
              margin-top: 14px;
            }
          }
        }
      }
    }
    // 企业数字工厂业务场景
    .MathCompany {
      margin-top: 80px;
      .MathCompanyTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
      }
      .MathCompanyImg {
        width: 1210px;
        height: 653px;
        margin-top: 40px;
      }
      .MathCompanyDetail {
        height: 636px;
        margin-top: 51px;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        li {
          width: 390px;
          height: 182px;
          background-color: #fff;
          /* background: url("~assets/img/SolutionOne/BG@2x.png") no-repeat;
          background-size: cover;
          background-position: center; */
          margin-right: 25px;
          &:nth-child(3n){
              margin-right: 0px;
          }
          &:nth-child(5){
              .headTop{
                  .MathComIcons{
                       margin: 10px 0 0 185px;
                  }
              }
          }
          &:nth-child(n + 7) {
            height: 212px;
          }
          .headTop {
            overflow: hidden;
            display: flex;
            height: 58px;
           background: url("~assets/img/SolutionOne/BG@2x.png") no-repeat;
           background-size: cover;
           
            .headTopTit {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 28px;
              color: #1e90ff;
              margin: 16px 0 0 30px;
            }
            .MathComIcons {
              width: 36px;
              height: 36px;
              margin: 10px 0 0 221px;
            }
          }
          .MathComContent {
            width: 330px;
            height: 70px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            margin: 30px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-backtop{
   img{
        width: 30px;
        height: 30px;
    }
}
</style>
